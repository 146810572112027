import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/atoms/SEO"

const StylesTestPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="uk-container">
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam commodo lobortis porttitor. Mauris tortor tortor, sodales id porta at, molestie commodo purus. Maecenas in dui quam. Cras quis aliquet ligula. Vivamus quam lectus, accumsan at eleifend ut, luctus at mauris. Proin non diam fringilla, egestas arcu id, posuere sapien. Ut placerat, mauris ac cursus feugiat, neque metus dignissim sem, sit amet sollicitudin metus lorem ut felis. Praesent et dui at mauris condimentum posuere. Mauris consequat, nibh hendrerit tempus bibendum, libero nisl mollis est, sed aliquet tellus neque vitae orci. Nullam sed dictum lacus. Aliquam erat volutpat.</p>

      <Link to="/page-2">A link</Link>

      <p>Vivamus porta euismod felis. Quisque interdum arcu nec est hendrerit interdum. Donec aliquam malesuada lorem at convallis. Sed consectetur iaculis vestibulum. Praesent vestibulum molestie elit, nec pretium dolor. Nunc consectetur sapien vel diam pharetra, et laoreet justo commodo. Sed interdum velit et libero pharetra tempor. Quisque feugiat porta odio, et vulputate nisi tristique eget. Vivamus nec nisi nec enim lobortis laoreet et vel tortor. Vivamus urna urna, semper in venenatis nec, volutpat quis nisi. Suspendisse velit tortor, euismod quis molestie at, tempus nec libero. Mauris semper nisi non velit sodales, id molestie odio pulvinar. Mauris ullamcorper blandit urna, gravida ultricies lacus pulvinar in. Phasellus lobortis ultrices varius. Quisque varius, ligula lobortis scelerisque sollicitudin, eros tortor laoreet dolor, vitae auctor dui nunc vel augue.</p>


      {/*<div data-uk-grid className="uk-child-width-1-2@m">
        <div className="uk-section-primary">
          <span uk-icon="icon: check"></span>
        
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">test</div>
            <div className="uk-card-body">hello</div>
          </div>
        </div>
        <div className="uk-section-secondary">grid 2</div>
        <div className="uk-section-muted">grid 3</div>
        <div className="uk-section-primary">grid 4</div>
      </div>*/}
    </div>
  </Layout>
)

export default StylesTestPage
